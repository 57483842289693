@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

body {
  font-family: "Space Grotesk", sans-serif;

}
.hvr-grow , .hvr-grow-shadow , .hvr-float-shadow , .hvr-bounce-in{
  display: inline-flex !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar { 
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #4e294f 0%, #b2567b 100%);;
  border-radius: 10px;
}

/* Base style for the custom radio label */
.custom-radio-label {
  align-items: start;
  cursor: pointer;
  position: relative;
  padding-left: 28px;
  font-size: 14px;
  
}

/* The radio circle */
.custom-radio-label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #666;
  background-color: transparent;
}

/* Filled radio circle when checked */
input[type="radio"].peer:checked + .custom-radio-label::before {
  border: none;
  background: linear-gradient(to left, #ec75e4 0%, #f976a5 100%);
}

input[type="radio"].peer:checked + .custom-radio-label::after {
  content: '';
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: black;
  border-radius: 50%;
}



/* Base style for the custom radio label */
.custom-radio-label1 {
  align-items: start;
  cursor: pointer;
  position: relative;
  padding-left: 28px;
  font-size: 14px;
  
}

/* The radio circle */
.custom-radio-label1::before {
  content: '';
  position: absolute;
  left: 0;
  top: 10%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #666;
  background-color: transparent;
}

/* Filled radio circle when checked */
input[type="radio"].peer:checked + .custom-radio-label1::before {
  border: none;
  background: linear-gradient(to left, #ec75e4 0%, #f976a5 100%);
}

input[type="radio"].peer:checked + .custom-radio-label1::after {
  content: '';
  position: absolute;
  left: 6px;
  top: 10%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: black;
  border-radius: 50%;
}

.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
}

.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  right: 50%;
  bottom: -3px !important;
  background: #f976a5 !important;
  height: 4px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}


@media screen and (min-device-width: 768px) and (max-device-width: 1023px) {

  .custom-radio-label1::before {
    content: '';
    position: absolute;
    left: 0;
    top: 14%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #666;
    background-color: transparent;
  }
  
  /* Filled radio circle when checked */
  input[type="radio"].peer:checked + .custom-radio-label1::before {
    border: none;
    background: linear-gradient(to left, #ec75e4 0%, #f976a5 100%);
  }
  
  input[type="radio"].peer:checked + .custom-radio-label1::after {
    content: '';
    position: absolute;
    left: 6px;
    top: 14%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    background-color: black;
    border-radius: 50%;
  }

}
@media screen and (max-device-width: 480px) and (orientation: portrait) {}
@media (min-width: 320px) and (max-width: 375px){}