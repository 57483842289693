/* body{
    background-color: #09090B;
} */

.bg-shadow{
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.all-btn {
    background: linear-gradient(to left, #ec75e4 0%, #f976a5 100%);
    font-weight: 900;
}

.btn-border {
    border: 2px solid;
}

.btn-allside {
    position: relative;
    /* padding: 1.3rem; */
    /* backdrop-filter: blur(10px); */
}

.btn-allside::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 8px;
    border: 0.5px solid transparent;
    background: linear-gradient(90deg, rgba(249, 118, 165, 1) 0%, rgba(236, 117, 228, 1) 50%, rgba(67, 67, 67, 1) 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
}

.check-bg {
    background-color: rgba(82, 67, 194, 0.15);
    border-radius: 999px;
}
.text-color {
    background: linear-gradient(to left, #ec75e4 0%, #f976a5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.font-color {
    /* background: linear-gradient(to left, #ec75e4 0%, #f976a5 100%); */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(
        to right,
        #ec75e4 20%,
        #f976a5 30%,
        #4e294f 70%,
        #f976a5 80%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-size: 500% auto;
    animation: textShine 5s ease-in-out infinite alternate;
    
}
@keyframes textShine {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

.font-hover:hover {
    background: linear-gradient(to left, #ec75e4 0%, #f976a5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.riding {
    background: linear-gradient(to bottom, #4e294f 0%, #b2567b 100%);
    object-fit: cover;
}
.text-bg:hover {
    background: linear-gradient(to bottom, #4e294f 0%, #b2567b 100%);
    object-fit: cover;
}

.ridingshadow {
    box-shadow: 40px 2px 0px -20px #202024, 75px 4px 0px -32px #100f12;
}

.ridingshadow1 {
    box-shadow: 40px 2px 0px -20px #cccccc, 75px 4px 0px -32px #ececec;
}

.tooltip-bg{
    background-image: url(../Images/Union.png);
    background-repeat: no-repeat;
    background-size: 95% 100%;
    background-position-x: center;
    /* width: 100%; */
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
.tooltip-bg1{
    background-image: url(../Images/Union\ \(1\).png);
    background-repeat: no-repeat;
    background-size: 95% 100%;
    background-position-x: center;
    /* width: 100%; */
}
.bg-pro{
    background-image: url(../Images/pro-bg.png);
    background-repeat: no-repeat;
    background-size: 95% 100%;
    background-position-x: center;
    /* width: 100%; */
}

.bgg {
    background-image: url(../Images/background.png);
    background-repeat: no-repeat;
    background-size: 95% 100%;
    background-position-x: center;
    width: 100%;
}

.bggg {
    background-image: url(../Images/round.png);
    background-repeat: no-repeat;
    background-size: 50% 80%;
    background-position-x: center;
    width: 100%;
}
.bgggg {
    background-image: url(../Images/gradiant.png);
    background-repeat: no-repeat;
     background-position-x: center;
    background-position-y: center;
    background-size: 55% 100%;
    height: 100vh;
    width: 100%;
}
.login-bgg {
    background-image: url(../Images/background.png);
    background-repeat: no-repeat;
    background-size: 70% 100%;
    background-position-x: center;
    width: 100%;
}

.login-bggg {
    background-image: url(../Images/round.png);
    background-repeat: no-repeat;
    background-size: 40% 80%;
    background-position-x: center;
    width: 100%;
    /* height: 80vh; */
}

.login-bgggg {
    background-image: url(../Images/gradiant.png);
    background-repeat: no-repeat;
     background-position-x: center;
    background-position-y: center;
    background-size: 55% 100%;
    height: 100vh;
    width: 100%;
}


.dashboard-bgg {
    background-image: url(../Images/background.png);
    background-repeat: no-repeat;
    background-size: 80% 100%;
    background-position-x: center;
    width: 100%;
}

.dashboard-bggg {
    background-image: url(../Images/round.png);
    background-repeat: no-repeat;
    background-size: 40% 90%;
    background-position-x: center;
    width: 100%;
}
.dashboard-bgggg {
    background-image: url(../Images/gradiant.png);
    background-repeat: no-repeat;
     background-position-x: center;
    background-position-y: center;
    background-size: 55% 100%;
    /* height: 100vh; */
    width: 100%;
}

.policy-bgg {
    background-image: url(../Images/background.png);
    background-repeat: no-repeat;
    background-size: 90% 70%;
    background-position-x: center;
    width: 100%;
}

.policy-bggg {
    background-image: url(../Images/round.png);
    background-repeat: no-repeat;
    background-size: 40% 55%;
    background-position-x: center;
    width: 100%;
}

.policy-bgggg {
    background-image: url(../Images/gradiant.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 50% 50%;
    /* height: 70vh; */
    width: 100%;
}
.security-bgg {
    background-image: url(../Images/background.png);
    background-repeat: no-repeat;
    background-size: 90% 60%;
    background-position-x: center;
    width: 100%;
}

.security-bggg {
    background-image: url(../Images/round.png);
    background-repeat: no-repeat;
    background-size: 40% 55%;
    background-position-x: center;
    width: 100%;
}

.security-bgggg {
    background-image: url(../Images/gradiant.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 50% 50%;
    /* height: 70vh; */
    width: 100%;
}

.features-bgg {
    background-image: url(../Images/background.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position-x: center;
    /* height: 100vh; */
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.features-bg {
    background-image: url(../Images/round.png);
    background-repeat: no-repeat;
    background-size: 50% 80%;
    background-position-x: center;
    background-position-y: center;
    width: 100%;
}

.features-bggg {
    background-image: url(../Images/gradiant.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;    
    background-size: 55% 100%;
    width: 100%;
}

.contribute-bgg {
    background-image: url(../Images/background.png);
    background-repeat: no-repeat;
    background-size: 55% 100%;
    background-position-x: center;
    width: 100%;
}

.contribute-bggg {
    background-image: url(../Images/round.png);
    background-repeat: no-repeat;
    background-size: 40% 80%;
    background-position-x: center;
    background-position-y: center;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.try-bgg {
    background-image: url(../Images/background.png);
    background-repeat: no-repeat;
    background-size: 150% 80%;
    background-position-x: center;
    width: 100%;
}

.try-bggg {
    background-image: url(../Images/round.png);
    background-repeat: no-repeat;
    background-size: 100% 80%;
    background-position-x: center;
    /* height: 100vh; */
    height: max-content;

    width: 100%;
}

.try-bgggg {
    background-image: url(../Images/gradiant.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;    
    background-size: 90% 50%;
    /* height: 100vh; */
    width: 100%;
}


.glass {
    background: rgba(255, 255, 255, 0.050980392156862744);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
    backdrop-filter: blur(2.9px);
    -webkit-backdrop-filter: blur(2.9px);
}

.glass1 {
    background: #7c7c7c0d;
    /* border-radius: 10px; */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(124, 124, 124, 0.3);
}

.glass2 {
    background: #d9d9d9;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(217, 217, 217, 0.3);
}

.glass3 {
    background: #ececec;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(236, 236, 236, 0.3);
}

.glass4 {
    background: #18181b;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(24, 24, 27, 0.3);
}

.glass5 {
    background: #0000000d;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.glass6 {
    background: #ffffff0d;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg,
            rgba(249, 118, 165, 1) 0%,
            rgba(236, 117, 228, 1) 50%,
            rgba(67, 67, 67, 1) 100%);
    backdrop-filter: blur(2.9px);
    -webkit-backdrop-filter: blur(2.9px);
}
.glass7 {
    background: #18181b;
    border: 1.5px solid rgba(134, 146, 166, 0.2);
    /* border-radius: 16px; */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.border-top {
    border-top: 3px solid;
    /* Adjust the border width as needed */
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg,
            rgba(249, 118, 165, 1) 0%,
            rgba(236, 117, 228, 1) 50%,
            rgba(67, 67, 67, 1) 100%);
}

.pricing {
    position: relative;
    padding: 1.3rem;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.pricing::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    border: 4px solid transparent;
    background: linear-gradient(90deg, rgba(249, 118, 165, 1) 0%, rgba(236, 117, 228, 1) 25%, rgba(236, 117, 228, 0.5) 50%)  border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    transition: opacity 0.3s ease;
    opacity: 0; /* initially hidden */
    z-index: -1; /* behind the card content */
}

.pricing:hover::before {
    opacity: 1; /* visible on hover */
}

.pricing:hover {
    /* Optionally, you can add additional hover styles here */
    box-shadow: 0 4px 20px rgba(236, 117, 228, 0.4); /* Example shadow */
}

.popular-allside {
    position: relative;
    padding: 1.3rem;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.popular-allside::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    border: 4px solid transparent;
    background: linear-gradient(90deg, rgba(249, 118, 165, 1) 0%, rgba(236, 117, 228, 1) 25%, rgba(236, 117, 228, 0.5) 50%)  border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    transition: opacity 0.3s ease;
    /* opacity: 0; initially hidden */
    z-index: -1; 
}

.border-all {
    border: 0.5px solid rgba(255, 255, 255, 0.2);
}

.border-allside {
    position: relative;
    padding: 1.3rem;
    /* backdrop-filter: blur(10px); */
}

.border-allside::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    border: 0.5px solid transparent;
    background: linear-gradient(90deg, rgba(249, 118, 165, 1) 0%, rgba(236, 117, 228, 1) 50%, rgba(67, 67, 67, 1) 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
}

.ai-allside {
    position: relative;
    /* backdrop-filter: blur(10px); */
}

.ai-allside::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    border: 1px solid transparent;
    background: linear-gradient(90deg, rgba(134, 146, 166, 0.57) 70%, rgba(52, 56, 64, 0.57) 30%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
}


input {
    background: transparent;
    /* Transparent background */
    border-radius: 4px;
    /* Rounded corners */
    padding: 8px;
    /* Padding inside the input */
    outline: none;
    /* Remove default outline */
    transition: box-shadow 0.3s ease;
    /* Smooth transition for box-shadow */
}

/* Gradient focus ring effect */
input:focus-visible {
    box-shadow:
        0 0 0 0.5px rgba(0, 0, 0, 0.2),
        0 0 0 0.5px #f976a580,
        0 0 0 0.5px rgba(236, 117, 228, 0.5),
        0 0 0 0.5px rgba(249, 118, 165, 0.5),
        /* Layered shadows for gradient effect */
        0 0 0 0.5px rgba(236, 117, 228, 0.5);
    /* Outer shadow layer for more gradient effect */
    border: none;
    /* Remove base border */
}

textarea {
    background: transparent;
    /* Transparent background */
    border-radius: 4px;
    /* Rounded corners */
    padding: 8px;
    /* Padding inside the input */
    outline: none;
    /* Remove default outline */
    transition: box-shadow 0.3s ease;
    /* Smooth transition for box-shadow */
}

/* Gradient focus ring effect */
textarea:focus-visible {
    box-shadow:
        0 0 0 0.5px rgba(0, 0, 0, 0.2),
        0 0 0 0.5px rgba(249, 118, 165, 0.5),
        0 0 0 0.5px rgba(236, 117, 228, 0.5),
        0 0 0 0.5px rgba(249, 118, 165, 0.5),
        /* Layered shadows for gradient effect */
        0 0 0 0.5px rgba(236, 117, 228, 0.5);
    /* Outer shadow layer for more gradient effect */
    border: none;
    /* Remove base border */
}

/* Style for the select element */
.custom-select {
    appearance: none;
    /* Removes the default arrow */
    background-color: transparent;
    /* White background */
    border: 1px solid #ccc;
    /* Light gray border */
    padding: 10px;
    /* Padding for text inside the dropdown */
    border-radius: 8px;
    /* Rounded corners */
    font-size: 16px;
    /* Text size */
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="gray"><polygon points="0,0 10,10 20,0"/></svg>');
    /* Custom dropdown arrow */
    background-repeat: no-repeat;
    background-position: right 10px center;
    /* Position of the arrow */
    background-size: 15px;
    /* Size of the arrow */
}

/* Hover state */
.custom-select:hover {
    border-color: #4e294f;
    /* Green border on hover */
}

/* Focus state */
.custom-select:focus {
    border-color: #4e294f;
    /* Green border when focused */
    outline: none;
    /* Remove default focus outline */
}

/* Option styling (not supported in all browsers but a fallback if needed) */
.custom-select option {
    background-color: #09090B;
    /* White background for options */
    opacity: 50%;
    color: #ffffff;
    /* Text color */
}

.custom-select option:hover {
    background-color: #b2567b;
    /* White background for options */
    color: #333;
    /* Text color */
}




/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked~.checkmark {
    background-color: #FE7689 !important;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}


@keyframes zoomEffect {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.zoom-animation {
    animation: zoomEffect 2s infinite;
    transition: transform 0.3s ease-in-out;
}

.typing-effect {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    animation: typing 4s steps(30) infinite normal both,
        blink-caret 0.75s step-end infinite;
    transition: border-right 0.3s ease-in-out;
}

.typing-effect::after {
    content: "";
    border-right: 2px solid;
    display: inline-block;
    height: 0.8em;
    vertical-align: bottom;
    animation: blink-caret 0.75s step-end infinite;
    transition: border-right 0.3s ease-in-out;
    border-image-source: linear-gradient(90deg,
            rgba(249, 118, 165, 1) 0%,
            rgba(236, 117, 228, 1) 50%,
            rgba(67, 67, 67, 1) 100%);
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes blink-caret {
    50% {
        border-image-slice: 1;
        border-image-source: linear-gradient(90deg,
                rgba(249, 118, 165, 1) 0%,
                rgba(236, 117, 228, 1) 50%,
                rgba(67, 67, 67, 1) 100%);
    }
}



@media only screen and (min-width: 1199px) and (max-width: 1440px) {
    .bgg {
        width: 100%;
        background-size: 125% 115%;
    }

    .bggg {
        height: 70vh;
        width: 100%;
        background-size: 90% 80%;
    }

    .bgggg {
        background-size: 100% 70%;
        height: 60vh;
        width: 100%;
    }

    .login-bggg {
        /* height: 100vh; */
        width: 100%;
        background-size: 50% 80%;
    }

}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
    .bgg {
        background-size: cover;
    }

    .login-bggg {
        /* height: 100vh; */
        width: 100%;
        background-size: 60% 80%;
    }

    .contribute-bggg {
        background-size: cover;
    }

    .try-bgg {
        background-size: 180% 100%;
        width: 100%;
    }

    .try-bggg {
        background-size: 100% 80%;
        /* height: 100vh; */
        height: max-content;
        width: 100%;
        margin-bottom: 20px;
    }

    .try-bgggg {
        background-size: 90% 100%;
        height: 100vh;
        width: 100%;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1023px) {
    .bgg {
        background-size: cover;
    }

    .bggg {
        background-size: 80% 80%;
    }

    .login-bggg {
        background-size: 80% 80%;
    }

    .features-bgg {
        background-size: 170% 100%;
    }

    .features-bggg {
        background-size: 100% 100%;
    }

    .features-bg {
        background-size: 90% 80%;
    }

    .contribute-bggg {
        background-size: 80% 80%;
    }

    .try-bgg {
        background-size: 180% 100%;
        width: 100%;
    }

    .try-bggg {
        background-size: 100% 80%;
        /* height: 100vh; */
        height: max-content;
        width: 100%;
        margin-bottom: 20px;
    }

    .try-bgggg {
        background-size: 90% 100%;
        height: 100vh;
        width: 100%;
    }
}

@media screen and (max-device-width: 480px) and (orientation: portrait) {
    .bgg {
        background-size: cover;
        width: 100%;
    }

    .bggg {
        height: 60vh;
        width: 100%;
        background-size: 90% 80%;
    }

    .login-bggg {
        height: 100vh;
        width: 100%;
        background-size: 100% 80%;
    }

    .bgggg {
        background-size: 100% 70%;
        height: 60vh;
        width: 100%;
    }

    .features-bgg {
        background-size: 200% 100%;
    }

    .features-bggg {
        background-size: 100% 100%;
    }

    .features-bg {
        background-size: 90% 80%;
    }

    .contribute-bggg {
        background-size: 80% 80%;
    }

    .chat-widget {
        width: 95%;
        /* Make chat widget responsive */
        right: 5px;
        bottom: 8rem;
    }

}

@media (min-width: 320px) and (max-width: 375px) {
    .riding {
        width: 90%;
    }

    .chat-widget {
        width: 95%;
        /* Make chat widget responsive */
        right: 5px;
        bottom: 8rem;
    }

    .bgggg {
        background-size: 100% 70%;
        height: 70vh;
        width: 100%;
    }

    .home {
        padding-top: 0rem;
    }

    .social {
        display: none;
    }

    .Why {
        padding: 0px;
    }

    .rating {
        flex-direction: column;
        width: auto;
    }

    .users {
        width: 100%;
    }

    .bgg {
        background-size: cover;
        width: 100%;
    }

    .bggg {
        height: 60vh;
        width: 100%;
        background-size: 90% 80%;
    }

    .features-bg {
        background-size: 100% 80%;
    }

    .features-bggg {
        background-size: 100% 100%;
    }

    .contribute-bggg {
        background-size: 60% 80%;
    }
}

@media (max-width: 320px) {
    .bgg {
        background-size: cover;
        width: 100%;
    }

    .bggg {
        height: 60vh;
        width: 100%;
        background-size: 90% 80%;
    }

    .bgggg {
        background-size: 100% 70%;
        height: 70vh;
        width: 100%;
    }

    .contribute-bggg {
        background-size: 80% 80%;
    }

    h2 {
        font-size: 1.5rem;
        /* Adjust the heading size */
        line-height: 2rem;
    }

    p {
        font-size: 0.875rem;
        /* Adjust the paragraph text size */
    }

    .chat-widget {
        width: 95%;
        /* Make chat widget responsive */
        right: 5px;
        bottom: 8rem;
    }

    .robot {
        height: 140px;
        width: 140px;
        top: -26px;
        right: 6px;
    }

    .ai3 {
        height: 140px;
        width: 140px;
        right: 6px;
        bottom: 1rem;
    }

    .ai2 {
        height: 115px;
        width: 115px;
        left: 6px;
        bottom: 1rem;
    }

    .about-img .glass {
        height: 300px;
    }

    /* Modify button styles */
    button {
        padding: 8px 12px;
    }
}